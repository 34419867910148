@font-face {
  font-family: Akkurat;
  src: url("../fonts/Akkurat-Regular.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: bold;
  src: url("../fonts/Akkurat-Bold.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-style: italic;
  src: url("../fonts/Akkurat-Italic.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: bold;
  font-style: italic;
  src: url("../fonts/Akkurat-Bold-Italic.otf") format("opentype"); }

@font-face {
  font-family: Akkurat Light;
  font-weight: 300;
  src: url("../fonts/Akkurat-Light-Regular.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/Akkurat-Light-Italic.otf") format("opentype"); }

h1, h2, h3, h4, h5, h6 {
  font-family: Akkurat Light, "Helvetica Neue", Helvetica, sans-serif; }

h2 {
  font-size: 26px; }

h3 {
  font-size: 22px; }

h2 {
  margin: 1em 0; }

body {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 100%;
  /* 16px */
  color: #333;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4; }

.logo {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .logo h1 {
    margin: 0;
    font-size: 2em; }
  .logo .image {
    margin: 1em;
    width: 3em; }

.speed-test-app1 {
  display: flex;
  flex-direction: column;
  margin: 0 6em; }

.speed-test-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 1em; }
  .speed-test-container h2 {
    font-size: 2em;
    background-color: #FFFFFF;
    margin: 0;
    padding-top: 1em; }
  .speed-test-container .speed-test {
    width: 100%;
    height: 38em; }

.disclaimer {
  font-size: 0.8em; }

@media only screen and (max-width: 768px) {
  body {
    font-size: 75%;
    /* 12px */ }
  p {
    font-size: 16px;
    color: #1A2738; }
  .speed-test-app {
    margin: 0; }
  .speed-test-container .speed-test {
    height: 48em; } }

* {
  box-sizing: border-box; }

.speed-info-box:after {
  content: "";
  display: table;
  clear: both; }

@media print, screen and (min-width: 1024px) {
  .end-page-tips {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 15px; } }

@media print, screen and (min-width: 768px) {
  .end-page-tips {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 15px; } }

.end-page-tips-content {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.end-page-tips-header {
  font-family: "Akkurat Light", Helvetica, Roboto, Arial, sans-serif;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 8px; }

.end-page-tips-body {
  font-size: 13px;
  line-height: 22px;
  padding-bottom: 15px; }

.info-box {
  background-color: #f9fafa;
  border-bottom: 1px solid #E3E6E8;
  border-top: 1px solid #E3E6E8;
  padding-bottom: 15px; }
  @media screen and (min-width: 1024px) {
    .info-box .grid-x p {
      padding: 0 6px 0 6px; } }
  .info-box .grid-container {
    padding-left: 15px;
    padding-right: 15px; }

.img-center {
  display: block;
  margin: 15px auto 15px auto; }

.speed-tests h2 {
  margin: 0 auto;
  display: grid; }

.info-container {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem; }

@media (min-width: 600px) {
  .info-container {
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto; } }
