.app-error {
  text-align: center; }
  .app-error .center-image {
    display: block;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto; }

.usabilla {
  width: fit-content;
  margin: auto; }
