@font-face {
  font-family: Akkurat;
  src: url("../fonts/Akkurat-Regular.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: bold;
  src: url("../fonts/Akkurat-Bold.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-style: italic;
  src: url("../fonts/Akkurat-Italic.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: bold;
  font-style: italic;
  src: url("../fonts/Akkurat-Bold-Italic.otf") format("opentype"); }

@font-face {
  font-family: Akkurat Light;
  font-weight: 300;
  src: url("../fonts/Akkurat-Light-Regular.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/Akkurat-Light-Italic.otf") format("opentype"); }

h1, h2, h3, h4, h5, h6 {
  font-family: Akkurat Light, "Helvetica Neue", Helvetica, sans-serif; }

h2 {
  font-size: 26px; }

h3 {
  font-size: 22px; }

h2 {
  margin: 1em 0; }

body {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 100%;
  /* 16px */
  color: #333;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4; }

.logo {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .logo h1 {
    margin: 0;
    font-size: 2em; }
  .logo .image {
    margin: 1em;
    width: 3em; }

.speed-test-app1 {
  display: flex;
  flex-direction: column;
  margin: 0 6em; }

.speed-test-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 1em; }
  .speed-test-container h2 {
    font-size: 2em;
    background-color: #FFFFFF;
    margin: 0;
    padding-top: 1em; }
  .speed-test-container .speed-test {
    width: 100%;
    height: 38em; }

.disclaimer {
  font-size: 0.8em; }

@media only screen and (max-width: 768px) {
  body {
    font-size: 75%;
    /* 12px */ }
  p {
    font-size: 16px;
    color: #1A2738; }
  .speed-test-app {
    margin: 0; }
  .speed-test-container .speed-test {
    height: 48em; } }
