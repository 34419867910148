/**
 * Foundation for Sites by ZURB
 * Version 6.5.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@font-face {
  font-family: Akkurat;
  src: url("../fonts/Akkurat-Regular.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: bold;
  src: url("../fonts/Akkurat-Bold.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-style: italic;
  src: url("../fonts/Akkurat-Italic.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: bold;
  font-style: italic;
  src: url("../fonts/Akkurat-Bold-Italic.otf") format("opentype"); }

@font-face {
  font-family: Akkurat Light;
  font-weight: 300;
  src: url("../fonts/Akkurat-Light-Regular.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/Akkurat-Light-Italic.otf") format("opentype"); }

h1, h2, h3, h4, h5, h6 {
  font-family: Akkurat Light, "Helvetica Neue", Helvetica, sans-serif; }

h2 {
  font-size: 26px; }

h3 {
  font-size: 22px; }

h2 {
  margin: 1em 0; }

body {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 100%;
  /* 16px */
  color: #333;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4; }

.logo {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .logo h1 {
    margin: 0;
    font-size: 2em; }
  .logo .image {
    margin: 1em;
    width: 3em; }

.speed-test-app1 {
  display: flex;
  flex-direction: column;
  margin: 0 6em; }

.speed-test-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 1em; }
  .speed-test-container h2 {
    font-size: 2em;
    background-color: #FFFFFF;
    margin: 0;
    padding-top: 1em; }
  .speed-test-container .speed-test {
    width: 100%;
    height: 38em; }

.disclaimer {
  font-size: 0.8em; }

@media only screen and (max-width: 768px) {
  body {
    font-size: 75%;
    /* 12px */ }
  p {
    font-size: 16px;
    color: #1A2738; }
  .speed-test-app {
    margin: 0; }
  .speed-test-container .speed-test {
    height: 48em; } }

.hide-section {
  display: none; }

.un-hide-section {
  display: block; }

@media only screen and (max-width: 1024px) {
  .speed-test-container-main {
    position: relative;
    z-index: 0 !important;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    width: calc(80vw + 1rem); }
    .speed-test-container-main .ookla-speed-test {
      width: 100%;
      height: 43em; } }
    @media only screen and (max-width: 1024px) and (min-width: 769px) and (max-width: 1023px) {
      .speed-test-container-main .ookla-speed-test {
        height: 40em; } }
    @media only screen and (max-width: 1024px) and (max-width: 768px) {
      .speed-test-container-main .ookla-speed-test {
        height: 50em; } }
    @media only screen and (max-width: 1024px) and (min-width: 1024px) {
      .speed-test-container-main .ookla-speed-test {
        height: 40em; } }

@media only screen and (max-width: 1024px) {
    .speed-test-container-main .powered_by_ookla {
      font-size: 14px;
      margin-bottom: 20px; }
    .speed-test-container-main .speed-ookla-link {
      text-decoration: underline;
      color: #2c74d3; } }

@media only screen and (min-width: 1025px) {
  .speed-test-container-main {
    position: relative;
    z-index: 0 !important;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    padding: 0; }
    .speed-test-container-main .ookla-speed-test {
      width: 100%;
      height: 43em; } }
    @media only screen and (min-width: 1025px) and (min-width: 769px) and (max-width: 1023px) {
      .speed-test-container-main .ookla-speed-test {
        height: 40em; } }
    @media only screen and (min-width: 1025px) and (max-width: 768px) {
      .speed-test-container-main .ookla-speed-test {
        height: 50em; } }
    @media only screen and (min-width: 1025px) and (min-width: 1024px) {
      .speed-test-container-main .ookla-speed-test {
        height: 40em; } }

@media only screen and (min-width: 1025px) {
    .speed-test-container-main .powered_by_ookla {
      font-size: 14px;
      margin-bottom: 20px; }
    .speed-test-container-main .speed-ookla-link {
      text-decoration: underline;
      color: #2c74d3; } }

.alert-success--bar.speed-test-complete {
  margin-bottom: 0;
  border-radius: 0; }
  @media only screen and (min-width: 1280px) {
    .alert-success--bar.speed-test-complete {
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem;
      padding-left: 32px; } }

.left-padding-large {
  padding-left: 15px; }

.tile--icon {
  padding-right: 10px;
  color: #1A2738 !important; }

.run-again--text {
  text-align: right;
  font-size: 15px;
  color: #000;
  padding-right: 10px;
  text-decoration: underline; }

/*.possibilities-box{
  background-color: $speedTest-tips;
  @media screen and (min-width: 1024px) {
    padding-top: 1px;
    border-top-color: #E3E6E8;
    border-top-style: solid;
    border-top-width: 1px;
  }
}*/
@media screen and (min-width: 1024px) {
  h2.typography.text-center.possibilities-header {
    padding-bottom: 15px; } }

.accordion-speed-result th#accordion--item--speed-title--text-one {
  width: 65%; }

.accordion-speed-result h2#accordion--item--speed-title--h2 {
  padding-left: 0; }

.accordion-speed-result .first-span.is-active {
  border-top: none; }

.accordion-speed-result .is-active {
  border-top: 1px solid #e3e6e8; }

.speed-test-result-accordion ul {
  margin-bottom: 0 !important; }

tbody.instruction-list tr:nth-child(even) {
  border-top: solid #E3E6E8 1px;
  padding: 10px;
  margin: 0;
  background-color: #FFFFFF; }
  tbody.instruction-list tr:nth-child(even) .step-number {
    color: #0060A4;
    font-size: large; }

.hide-row {
  display: none; }

.possibilities-span {
  padding: 0 10px 0 10px; }

.speed-test-result-tooltip {
  display: inline-block;
  position: relative; }

.tooltip__arrow {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: #2c74d3;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  margin-left: 269px;
  bottom: -8px; }
  @media screen and (min-width: 320px) {
    .tooltip__arrow {
      left: -70px; } }
  @media screen and (min-width: 375px) {
    .tooltip__arrow {
      left: -67px; } }

.speed-result-info {
  color: #2c74d3; }

.speed-test-result-tooltip-text {
  display: none;
  visibility: visible;
  background-color: #2c74d3;
  color: #fff;
  border-radius: 8px;
  right: 0;
  font-size: 17px;
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  line-height: 20px;
  padding: 0 16px 20px 20px; }
  @media screen and (min-width: 320px) {
    .speed-test-result-tooltip-text {
      left: -194px;
      top: -139px;
      width: 296px; } }
  @media screen and (min-width: 375px) {
    .speed-test-result-tooltip-text {
      left: -197px;
      top: -120px;
      width: 349px; } }

.speed-result-close {
  color: #FFFFFF; }

.tooltip-inactive {
  display: none; }

.tooltip-active {
  display: block; }

.speed-test-result-explanation {
  display: block;
  padding-top: 1px; }
  @media screen and (min-width: 768px) {
    .speed-test-result-explanation {
      display: none; } }
  .speed-test-result-explanation .grid-container {
    padding-left: 15px;
    padding-right: 15px; }

.accordion-speed-result {
  display: block; }
  @media screen and (min-width: 768px) {
    .accordion-speed-result {
      display: none; } }
  .accordion-speed-result .speed-test-result-accordion--item--title {
    padding-left: 15px;
    padding-right: 5px; }
  .accordion-speed-result .accordion-content p {
    padding-left: 0px;
    padding-right: 0px; }
  .accordion-speed-result .accordion--item:last-child {
    border-bottom: 1px solid #e3e6e8; }

h2.typography.speed-test-result-explanation-header {
  /*padding-left: 15px;*/
  padding-bottom: 15px; }

.result-explanation-header {
  font-size: initial; }

.speed-test-result-explanation-content p {
  color: white; }
  .speed-test-result-explanation-content p a {
    color: white;
    text-decoration: underline; }
  .speed-test-result-explanation-content p a:visited {
    color: white; }
  .speed-test-result-explanation-content p .hrefSup {
    display: inline-block;
    border-bottom: 1px solid #fff;
    padding-bottom: 11px; }

.possibilities-box-content {
  padding-bottom: 45px; }
  .possibilities-box-content p a {
    text-decoration: underline; }
  .possibilities-box-content p a:visited {
    color: #2c74d3; }
  .possibilities-box-content p .hrefSup {
    display: inline-block;
    border-bottom: 1px solid #2c74d3;
    padding-bottom: 11px; }

.fix-me-box {
  padding-top: 1px;
  padding-bottom: 1.5rem;
  background-color: #1a2738;
  color: #DBDFE2; }
  @media screen and (min-width: 1024px) {
    .fix-me-box {
      padding-left: 15px; } }
  @media screen and (min-width: 768px) {
    .fix-me-box {
      padding-left: 15px; } }

.fix-me-content {
  padding-right: 5px;
  padding-left: 5px; }
  @media screen and (min-width: 1024px) {
    .fix-me-content {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media screen and (min-width: 768px) {
    .fix-me-content {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media print, screen and (min-width: 40em) {
    .fix-me-content .grid-container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media print, screen and (min-width: 768px) {
    .fix-me-content .grid-container {
      padding-right: 15px;
      padding-left: 15px; } }
  .fix-me-content p {
    color: #DBDFE2; }
  .fix-me-content .fix-button {
    padding-bottom: 16px; }
  .fix-me-content .fix-button a {
    text-align: center; }

.button.primary {
  z-index: 0 !important; }

.feedback-container {
  background-color: #F9FAFA;
  border-top-style: solid;
  border-top-color: #e3e6e8;
  border-top-width: 1px;
  padding-left: 10px; }

.top-gradient {
  width: 100%;
  right: 0;
  top: 0; }

@media screen and (min-width: 1024px) {
  .ta-footer .grid-x {
    margin-left: -4.75rem !important; } }

@media screen and (min-width: 768px) {
  .alert-success-sticky {
    width: 100% !important;
    position: fixed !important;
    top: 48px;
    bottom: auto;
    z-index: 999; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .alert-success-sticky {
    width: 100% !important;
    position: fixed !important;
    top: 48px;
    bottom: auto;
    z-index: 999; } }

@media screen and (min-width: 768px) {
  .alert-success-non-sticky {
    width: 100% !important;
    position: fixed !important;
    top: 0 !important;
    bottom: auto;
    z-index: 999; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .alert-success-non-sticky {
    width: 100% !important;
    position: fixed !important;
    top: 0 !important;
    bottom: auto;
    z-index: 999; } }

@media screen and (min-width: 768px) {
  .main-header-sticky {
    width: 100%;
    position: fixed;
    top: 0;
    bottom: auto; } }

.top-gradient-sticky {
  width: 100%;
  padding-top: 51px;
  padding-left: 5px;
  bottom: auto; }

.top-gradient-non-sticky {
  width: 100%;
  padding-top: 0 !important;
  padding-left: 5px;
  bottom: auto; }

.speed-measure {
  font-weight: bold; }

section {
  padding: 15px; }

.speed-title--h2 {
  margin: 15px;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 1.25rem !important;
  font-weight: 600 !important; }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .grid-container.speed-test-alertbox {
    padding-left: 15px !important; } }

@media screen and (min-width: 1024px) {
  .speed-test-alertbox .grid-x {
    padding-left: 15px; } }

.speed-test-result-display .grid-x p {
  padding: 0 6px 0 6px; }

@media print, screen and (min-width: 48em) {
  .medium-to-large {
    display: block !important; }
  .medium-padding {
    padding: 0 15px 0 15px; }
  .small-to-medium {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  .medium-to-large {
    display: none !important; }
  .small-to-medium {
    display: block !important; } }

@media screen and (min-width: 1024px) {
  .ta-table th {
    border-right: 1px solid #C8CED2; } }

@media screen and (min-width: 1024px) {
  .ta-table td {
    border-right: 1px solid #C8CED2; } }

td.text-left {
  border-right: 0 !important; }
  @media screen and (min-width: 768px) {
    td.text-left {
      border-left: 1px solid #C8CED2; } }

.hide-content {
  display: none; }

.tab-data {
  padding-top: 60px;
  padding-left: 10px; }

.space-tab {
  padding-top: 45px; }

.singleline-tile.options .btn {
  border-left: 0;
  border-right: 0; }

.page-grid-container {
  padding-bottom: 2px; }

.flex-container {
  width: max-content;
  margin: 0 auto;
  display: -webkit-flex;
  /* Safari */
  display: flex;
  /* Standard syntax */ }

.flex-container .speed-test-result {
  padding: 10px;
  -webkit-flex: 1;
  /* Safari */
  -ms-flex: 1;
  /* IE 10 */
  flex: 1;
  /* Standard syntax */
  font-family: "Akkurat Light", Helvetica, Roboto, Arial, sans-serif !important;
  font-size: 5rem !important;
  color: #2C74D3;
  text-align: right; }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .flex-container .speed-test-result {
      font-size: 4rem !important; } }

.flex-container .speed-test-result.unit {
  font-family: "Akkurat Light", Helvetica, Roboto, Arial, sans-serif !important;
  font-size: 1.875rem !important;
  color: #2C74D3;
  text-align: left;
  padding-top: 62px; }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .flex-container .speed-test-result.unit {
      padding-top: 44px;
      padding-left: 0; } }

@media screen and (min-width: 767px) {
  .fix-me-header {
    font-size: 26px !important; } }

.speed-test-header {
  padding-top: 20px; }

.accordion-speed-result h2#accordion--item--speed-title--h2 {
  padding-left: 0;
  font-family: Helvetica Neue,Akkurat Light,Helvetica,sans-serif; }

@media screen and (min-width: 1024px) {
  .align-to-logo {
    padding-left: 25px !important;
    padding-right: 25px !important; } }

.img-grid-row {
  grid-row: 1 / span 3; }

.speed-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; }

.download-speed {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex: 1; }

.upload-speed {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex: 1; }

@media (max-width: 550px) {
  .speed-details {
    display: flex;
    flex-direction: column;
    width: 100%; } }

.speed-test-box {
  display: flex; }

.seed-test-run-again {
  width: 100%;
  border-bottom: 0.1rem solid transparent;
  border-image: linear-gradient(90deg, #0064d2, #5cd6e0 30%, #5e50bf 70%);
  border-image-slice: 1;
  box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);
  height: 3rem;
  justify-content: flex-start;
  align-items: center; }
